import React, { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetCurrencyIcon } from 'astra-core/hooks'
import { useQuickAmountsSettings } from 'astra-core/containers/SettingsProvider/useQuickAmountsSettings'
import { useSelector } from 'react-redux'
import { selectCurrencyMinBet } from 'astra-core/containers/CommonDataProvider'
import { getDefaultQuickAmountsSettings } from 'og-web-core/utils/settings/settings'
import {
  QuickAmountsTypes,
  ValidateAmountErrors
} from 'astra-core/containers/SettingsProvider'
import {
  maxAmountsCount,
  maxPercentValue,
  minPercentValue
} from 'astra-core/containers/SettingsProvider/constants'

import { Button, EButtonSizes, EButtonViews } from 'shared/ui/Button'
import { numericInputConfig } from 'shared/ui/DynamicInput/CurrencyDynamicInput'

import { maxAmountSumValue } from '../../constants'

import { QuickAmountSetting } from './components/QuickAmountSetting'
import * as S from './QuickAmountsSettings.styled'

export const QuickAmountsSettings = memo(() => {
  const minBet = useSelector(selectCurrencyMinBet)
  const defaultSettings = getDefaultQuickAmountsSettings(minBet)

  const { resetQuickAmountsSettings, canResetSettings, quickAmountsSettings } =
    useQuickAmountsSettings(defaultSettings)

  const currencyIcon = useGetCurrencyIcon()

  const { t } = useTranslation()

  const sumAmountValidationMessages = useMemo(
    () => ({
      minValueError: t('minimum amount', {
        amount: minBet,
        currency: currencyIcon
      }),
      maxValueError: t('input amount exceeded'),
      [ValidateAmountErrors.amountsCountError]: t('max templates count', {
        count: maxAmountsCount
      })
    }),
    [currencyIcon, minBet, t]
  )

  const percentAmountValidationMessages = useMemo(
    () => ({
      [ValidateAmountErrors.minValueError]: t('min percent', {
        value: minPercentValue
      }),
      [ValidateAmountErrors.maxValueError]: t('max percent', {
        value: maxPercentValue
      }),
      [ValidateAmountErrors.amountsCountError]: t('max templates count', {
        count: maxAmountsCount
      })
    }),
    [t]
  )

  return (
    <S.QuickAmountsSettingsWrapper>
      <S.QuickAmountsSettingsTitle>
        {t('amount template')}
      </S.QuickAmountsSettingsTitle>
      <QuickAmountSetting
        currencyIcon={currencyIcon}
        description="create your own values"
        maxValue={maxAmountSumValue}
        minValue={minBet}
        settings={quickAmountsSettings}
        title="sum units"
        type={QuickAmountsTypes.sum}
        validationMessages={sumAmountValidationMessages}
      />
      <QuickAmountSetting
        currencyIcon="%"
        description="create your own values"
        inputConfig={numericInputConfig}
        maxValue={maxPercentValue}
        minValue={minPercentValue}
        settings={quickAmountsSettings}
        title="percent of the balance"
        type={QuickAmountsTypes.percent}
        validationMessages={percentAmountValidationMessages}
      />
      {canResetSettings && (
        <Button
          size={EButtonSizes.S}
          view={EButtonViews.SECONDARY}
          onClick={resetQuickAmountsSettings}
        >
          {t('reset default settings')}
        </Button>
      )}
    </S.QuickAmountsSettingsWrapper>
  )
})
